<template>
  <div class="min-h-[2rem]" v-flux-loading="isLoading">
    <div class="text-xs" v-if="false">Geen geschiedenis beschikbaar.</div>
    <div v-else-if="!isLoading">
      <h3 class="mb-1 text-sm font-normal">Geschiedenis</h3>
      <ul class="m-0 space-y-3 p-0" v-if="data !== undefined" role="list">
        <li
          class="relative flex justify-between"
          v-for="([at, events], index) in data.entries()"
          :key="index"
        >
          <div class="absolute top-0 right-0 -bottom-6 flex w-3 justify-center">
            <div
              class="w-px bg-gray-200"
              v-if="!(index == data.size - 1)"
            ></div>
          </div>
          <div class="flex gap-x-2">
            <AppointmentLogActor :actor="events.at(0)?.payload.actor" />
            <ul class="p-0" role="list">
              <li class="flex" v-for="(log, idx) in events" :key="idx">
                <p
                  class="text text-text-quaternary flex-auto py-0.5 text-left text-xs leading-4"
                >
                  {{ getLogText(log) }}
                </p>
              </li>
            </ul>
          </div>
          <div class="flex shrink-0 items-start gap-x-2">
            <flux-tooltip
              class="leading-4"
              :content="
                at
                  .toZonedDateTimeISO('Europe/Amsterdam')
                  .toLocaleString('nl-NL', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })
              "
            >
              <time
                class="text-text-quaternary flex-none text-xs leading-4"
                :datetime="at.toString()"
                >{{ getLogTime(at) }}</time
              >
            </flux-tooltip>

            <div
              class="bg-background-primary relative flex h-5 w-3 flex-none items-center justify-center dark:bg-transparent"
            >
              <div
                class="bg-background-secondary h-1.5 w-1.5 rounded-full ring-1 ring-gray-300 dark:ring-neutral-100"
              ></div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { Appointment } from "@/models/Appointment";
import { fullName } from "@/models/Person";
import { $t } from "@/libraries/i18n";
import { Location } from "@/models/Location";
import moment from "moment";
import { getUser } from "@/libraries/plugins/getUser";
import { User } from "@/models/User";
import { AppointmentApi } from "@/libraries/repositories/appointmentRepositoryUsingApi";
import { useAppointmentLogs } from "@/composables/appointmentLog";
import AppointmentLogActor from "@/components/calendar/Appointment/AppointmentLogActor.vue";
import { AppointmentLog } from "@/queries/appointments/appointmentLogs";

const props = defineProps<{
  appointment: Appointment | AppointmentApi;
  locations: Location[];
}>();

const { data, isLoading } = useAppointmentLogs(
  computed(() => props.appointment.id),
);

function userName(id: number): User | undefined {
  return getUser().healthcare_provider.users.find((user) => id == user.id);
}

function locationName(id: number) {
  return props.locations.find((location: Location) => id == location.id)?.name;
}

function formatDate(instant: Temporal.Instant): string {
  return instant
    .toZonedDateTimeISO("Europe/Amsterdam")
    .toLocaleString("nl-NL", { day: "2-digit", month: "2-digit" });
}

function formatTime(instant: Temporal.Instant): string {
  return instant
    .toZonedDateTimeISO("Europe/Amsterdam")
    .toLocaleString("nl-NL", { hour: "2-digit", minute: "2-digit" });
}

function getLogText(log: AppointmentLog) {
  if (log.type === "AppointmentWasCreated") {
    const start = log.payload.start;
    const end = log.payload.end;
    return `maakt afspraak ${formatDate(start)}, ${formatTime(start)}–${formatTime(end)}`;
  } else if (log.type === "AppointmentStatusWasChanged") {
    return `wijzigt status naar ${$t(
      `appointment.status.${log.payload.status}`,
    )}`;
  } else if (log.type === "AppointmentLocationWasChanged") {
    return `wijzigt locatie naar ${locationName(log.payload.location_id)}`;
  } else if (log.type === "AppointmentTimeWasChanged") {
    const start = log.payload.start;
    const end = log.payload.end;

    return `wijzigt datum ${formatDate(start)}, ${formatTime(start)}–${formatTime(end)}`;
  } else if (log.type === "AppointmentWasRescheduled") {
    const start = log.payload.start;
    const end = log.payload.end;

    return `verplaats naar ${formatDate(start)}, ${formatTime(start)}–${formatTime(end)}`;
  } else if (log.type === "AppointmentPatientWasChanged") {
    return `wijzigt patient`;
  } else if (log.type === "AppointmentResponsibleUserWasChanged") {
    return `wijzigt verantwoordelijke naar ${fullName(
      userName(log.payload.user_id),
    )}`;
  } else if (log.type === "AppointmentUserWasAdded") {
    return `voegt ${fullName(userName(log.payload.user_id))} toe`;
  } else if (log.type === "AppointmentUserWasRemoved") {
    return `verwijdert ${fullName(userName(log.payload.user_id))}`;
  } else if (log.type === "AppointmentEmailWasSent") {
    const actor = log.payload.actor ?? {};
    if ("email" in actor || "zis_number" in actor) {
      return `heeft email ontvangen`;
    }
    return `heeft email verstuurd`;
  } else if (log.type === "AppointmentCreditWasReserved") {
    return `heeft ${log.payload.amount} credit${
      log.payload.amount > 1 ? "s" : ""
    } gereserveed`;
  } else if (log.type === "AppointmentCreditWasUnReserved") {
    return `heeft ${log.payload.amount} credit${
      log.payload.amount > 1 ? "s" : ""
    } vrijgegeven`;
  } else if (log.type === "AppointmentCreditWasSpent") {
    return `heeft ${log.payload.amount} credit${
      log.payload.amount > 1 ? "s" : ""
    } afgeschreven`;
  } else if (log.type === "AppointmentCreditWasUnSpent") {
    return `heeft ${log.payload.amount} credit${
      log.payload.amount > 1 ? "s" : ""
    } terug geschreven`;
  } else if (log.type === "AppointmentBlockedForGenerationWasChanged") {
    return `heeft de afspraak ${
      log.payload.block_generation ? "geblokkeerd" : "gedeblokkeerd"
    } voor facturatie`;
  } else if (log.type === "AppointmentTypeWasChanged") {
    return log.payload.appointment_type_name
      ? `wijzigt type ${log.payload.appointment_type_name}`
      : "verwijdert afspraak type";
  } else if (log.type === "AppointmentWasDeleted") {
    return `heeft de afspraak verwijderd`;
  } else if (log.type === "AppointmentReferralWasChanged") {
    if (log.payload.referralId === undefined) {
      return `heeft behandeltraject verwijderd`;
    }
    return `heeft behandeltraject gewijzigd`;
  } else if (log.type === "AppointmentWasImported") {
    return "heeft de afspraak geïmporteerd";
  } else if (log.type === "AppointmentPrivacyWasChanged") {
    if (log.payload.private) {
      return "heeft de afspraak privé gemaakt";
    } else {
      return "heeft de afspraak openbaar gemaakt";
    }
  }
}

function getLogTime(at: Temporal.Instant) {
  const datetime = moment(at.toString());

  return datetime.fromNow();
}

moment.updateLocale("nl", {
  relativeTime: {
    past: "%s",
    s: "nu",
    m: "1 min",
    mm: "%d min",
    h: "1 uur",
    d: "1 dag",
    w: "1 week",
    M: "1 maand",
    y: "1 jaar",
  },
});
</script>
